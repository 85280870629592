import { Box, List, ListItem } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import handleSignOut from "utils/signout"
import { useTranslation } from "react-i18next"

const UserDropdown = ({ user }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [cookie, setCookie, removeCookie] = useCookies(["loginToken"])

  const profileRoutes = [
    { id: 1, title: "Paketler", href: "/profile/package" },
    { id: 3, title: t("headerSignOut"), href: "#" },
  ]

  const disconnectHubAndSignOut = () => {
    handleSignOut(dispatch, removeCookie, history)
  }

  const options = profileRoutes.map((option, i) => (
    <ListItem
      key={i}
      sx={{
        paddingLeft: "15px",
        paddingRight: "35px",
        transition: "all .3s linear",
        fontWeight: 400,
        paddingTop: "3px",
        paddingBottom: "3px",
        color: "#b8c0c0",
        "&:focus": {
          backgroundColor: "",
        },
        "&:hover": {
          backgroundColor: "",
        },
        "&:first-of-type": {
          borderRadius: "0",
        },
        "&:last-of-type": {
          borderRadius: "0 0 10px 10px",
        },
      }}
      component={Link}
      onClick={option.id === 3 ? () => disconnectHubAndSignOut() : null}
      to={option.href}
    >
      {option.title}
    </ListItem>
  ))

  return (
    <Box
      sx={{
        position: "relative",
        cursor: "pointer",
        "& .MuiBox-root.MuiBox-root": {
          display: "flex",
          alignItems: "center",
        },
        "& .MuiList-root": {
          transition: "all 1s ease-in-out",
        },
        "&:hover": {
          "& .MuiList-root": {
            opacity: 1,
            visibility: "visible",
          },
        },
      }}
    >
      <Box
        display={"flex"}
        minWidth={185}
        padding={"8px"}
        className="targetBox"
        sx={{
          border: "2px solid",
          borderRadius: "10px !important",
          fontSize: "0.875rem",
          backgroundColor: "",
          direction: i18n.language === "ar" ? "initial" : null,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            borderRadius: "5px !important",
          }}
        >
          {/* <Avatar src="/broken-image.jpg" sx={{ height: 35, width: 35, borderRadius: '10px' }} /> */}
        </Box>
        <Box
          paddingLeft={"1px"}
          flex={1}
          overflow={"hidden"}
          sx={{
            position: "relative",
            textAlign: "right",
            "&:after": {
              content: "\"\"",
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              width: 0,
              height: 0,
              borderLeft: "6px solid transparent",
              borderRight: "6px solid transparent",
              borderTop: "6px solid #b8c0c0",
            },
          }}
        >
          <div
            style={{
              color: "#b8c0c0",
              position: "relative",
              fontWeight: 500,
            }}
          >
            <div style={{ lineHeight: "0.875rem", marginBottom: "2px" }}>
              {user.name}
            </div>

          </div>
        </Box>
      </Box>

      <List
        sx={{
          top: "50px !important",
          border: "2px solid",
          borderRadius: "0 0 10px 10px",
          fontSize: "0.875rem",
          backgroundColor: "",
          overflow: "hidden",
          position: "absolute",
          opacity: 0,
          visibility: "hidden",
          margin: "0",
          padding: "10px 0 10px 0",
          listStyle: "none",
          width: 185,
        }}
      >
        {options}
      </List>
    </Box>
  )
}

UserDropdown.propTypes = {
  user: PropTypes.object,
}

export default UserDropdown
