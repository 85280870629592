import React from "react"
import { Redirect, Route } from "react-router"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"

const AuthRoute = (props) => {
  const { type } = props
  const isAuthenticated = useSelector(state => state.main.isAuthenticated)
  if (type === "guest" && isAuthenticated) return <Redirect to="/" />
  else if (type === "private" && !isAuthenticated) return <Redirect to="/profile/package" />

  return <Route {...props} />
}

export default AuthRoute

AuthRoute.propTypes = {
  type: PropTypes.string,
}
