import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"
import TablePagination from "@mui/material/TablePagination"
import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import SearchIcon from "@mui/icons-material/Search"
import InputAdornment from "@mui/material/InputAdornment"

import { TextField } from "@mui/material"
import UserServices from "../../../../../services/userServices"

const Users = () => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [userData, setUserData] = useState([])

  const [filterText, setFilterText] = useState("")
  const handleOpenEditDialog = (user) => {
    setCurrentUser(user)
    setOpenEditDialog(true)
  }

  useEffect(() => {
    UserServices.getAllUsers().then((res) => {
      console.log(res.data)
      setUserData(res.data)
    })
  }, [])

// Filtreleme ve sıralamayı aynı anda uygulamak için bir işlev tanımlayalım
  const getSortedAndFilteredUsers = () => {
    const filteredUsers = userData.filter(user =>
      user.fullName.toLowerCase().includes(filterText.toLowerCase()) ||
      user.email.toLowerCase().includes(filterText.toLowerCase()) ||
      user.companyName.toLowerCase().includes(filterText.toLowerCase()) ||
      user.userPackageName.toLowerCase().includes(filterText.toLowerCase()),
    ).map(user => ({
      ...user,
      created: new Date(user.created).toISOString().split("T")[0],
      endDate: new Date(user.endDate).toISOString().split("T")[0],
    }))

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (sortConfig.key) {
        const directionMultiplier = sortConfig.direction === "asc" ? 1 : -1
        if (a[sortConfig.key] < b[sortConfig.key]) return -1 * directionMultiplier
        if (a[sortConfig.key] > b[sortConfig.key]) return 1 * directionMultiplier
        return 0
      }
      return 0
    })

    return sortedUsers
  }

  const paginatedUsers = getSortedAndFilteredUsers().slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  )

  const requestSort = (key) => {
    let direction = "asc"
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc"
    }
    setSortConfig({ key, direction })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <ArrowDropUpIcon fontSize="small" />
      ) : (
        <ArrowDropDownIcon fontSize="small" />
      )
    }
    return null
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Kullanıcı Listesi
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        margin="normal"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => requestSort("fullName")} style={{ cursor: "pointer" }}>
                Ad {renderSortIcon("fullName")}
              </TableCell>
              <TableCell onClick={() => requestSort("email")} style={{ cursor: "pointer" }}>
                Email {renderSortIcon("email")}
              </TableCell>
              <TableCell onClick={() => requestSort("companyName")} style={{ cursor: "pointer" }}>
                Firma {renderSortIcon("companyName")}
              </TableCell>
              <TableCell onClick={() => requestSort("created")} style={{ cursor: "pointer" }}>
                Üyelik Başlangıcı {renderSortIcon("created")}
              </TableCell>
              <TableCell onClick={() => requestSort("endDate")} style={{ cursor: "pointer" }}>
                Üyelik Bitişi {renderSortIcon("endDate")}
              </TableCell>
              <TableCell onClick={() => requestSort("userPackageName")} style={{ cursor: "pointer" }}>
                Üyelik Tipi {renderSortIcon("userPackageName")}
              </TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user, index) => (
              <TableRow key={index}>
                <TableCell>{user.fullName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.companyName}</TableCell>
                <TableCell>{user.created}</TableCell>
                <TableCell>{user.endDate}</TableCell>
                <TableCell>{user.userPackageName}</TableCell>
                <TableCell>{user.isAdmin ? <CheckIcon color="success" /> : null}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenEditDialog(user)}>
                    <EditIcon color="primary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <TablePagination
          count={getSortedAndFilteredUsers().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  )
}

export default Users

