import CheckIcon from "@mui/icons-material/Check"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import MoreVert from "@mui/icons-material/MoreVert"
import { Button, Grid, TextField, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import PropTypes from "prop-types"
import PricingCard from "./PricingCard"
import store from "../../../../redux/store"
import { setPackages, setSelectedPackage } from "../../../../redux/actions"
import { useSelector } from "react-redux"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import React, { useEffect } from "react"


const PricingTable = (props) => {
  const { licenseType, minimal } = props
  // Pagination için state kaldırıldı
  const [isMobile, setIsMobile] = React.useState(false)
  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  })

  const setDimension = () => {
    if (screenSize.dynamicWidth > 1024) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    setDimension()
  }, [])

  useEffect(() => {
    window.addEventListener("resize", setDimension)

    return (() => {
      window.removeEventListener("resize", setDimension)
    })
  }, [screenSize])

  const packages = useSelector((state) => state.main.packages)

  return (
    <Grid container direction={"row"} mt={2} paddingX={0}>
      {
        packages && packages.map((item, i) => (
          <Grid key={i} md={4} paddingX={2} item>
            <PricingCard
              licenseType={licenseType}
              minimal={minimal}
              item={item} />
          </Grid>
        ))
      }
    </Grid>
  )
}

function Row(props) {

  const { row, isMobile, selectedPage } = props
  const [open, setOpen] = React.useState(false)
  const [pricing, setPricing] = React.useState({ standart: 1, business: 11 })
  const packages = useSelector((state) => state.main.packages)

  const onStandartUserCountChange = (value) => {
    const packageItem = packages.find((item) => item.id === "standart")
    const data = {
      ...packageItem,
      userCount: parseInt(value),
    }
    const price = { standart: parseInt(value), business: pricing.business }
    setPricing(price)
    const packageList = packages.map((packageItem) => {
      if (packageItem.id === data.id) {
        return data
      } else {
        return packageItem
      }
    })
    store.dispatch(setSelectedPackage(data))
    store.dispatch(setPackages(packageList))
  }

  const onBusinessUserCountChange = (value) => {
    const packageItem = packages.find((item) => item.id === "business")
    const data = {
      ...packageItem,
      userCount: parseInt(value),
    }
    const price = { standart: pricing.standart, business: parseInt(value) }
    setPricing(price)
    const packageList = packages.map((packageItem) => {
      if (packageItem.id === data.id) {
        return data
      } else {
        return packageItem
      }
    })
    store.dispatch(setSelectedPackage(data))
    store.dispatch(setPackages(packageList))
  }

  return (
    <React.Fragment>
      <TableRow>

        {/* Title */}
        <TableCell sx={{ backgroundColor: "#F9F9F9", width: 200 }}>
          {row.name}
        </TableCell>

        {/* Expand Collapse Button */}
        <TableCell sx={{ backgroundColor: "#F9F9F9", width: 10 }} colSpan={1}>
          {row.i === 6 &&
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{ height: 25 }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>

        {/* Standart */}
        {
          (!isMobile || selectedPage === 1) && <TableCell align="center" sx={{ width: 340 }}>
            {row.i === 1 && <TextField
              type="number" size={"small"}
              sx={{ width: "100px" }}
              value={pricing.standart}
              inputProps={{ max: 10, min: 1, style: { textAlign: "center" } }}
              onChange={(e) => onStandartUserCountChange(e.target.value)}
              onInput={(e) => {
                if (parseInt(e.target.value) > 10) {
                  e.target.value = 10
                }
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
              }} />
            }

            {row.i === 2 || row.i === 3 ?
              <Typography variant="body1">{row.standart}</Typography> : <></>
            }

            {row.i === 4 || row.i === 5 ?
              <CheckIcon sx={{ color: "#1EC99F" }} /> : <></>
            }

            {row.i === 6 && <MoreVert sx={{ color: "#C2C2C2" }} />}

          </TableCell>
        }

        {/* Businness */}
        {
          (!isMobile || selectedPage === 2) &&
          <TableCell align="center" sx={{ width: 340, backgroundColor: "#F7F6FF" }}>
            {row.i === 1 &&
              <TextField
                type="number" size={"small"}
                sx={{ width: "100px" }}
                value={pricing.business}
                inputProps={{ max: 100, min: 11, style: { textAlign: "center" } }}
                onChange={(e) => onBusinessUserCountChange(e.target.value)}
                onInput={(e) => {
                  if (parseInt(e.target.value) > 100) {
                    e.target.value = 100
                  } else if (parseInt(e.target.value) < 11) {
                    e.target.value = 11
                  } else {
                    if (e.target.value) {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    } else {
                      e.target.value = 11
                    }
                  }
                }} />
            }

            {row.i === 2 || row.i === 3 ?
              <Typography variant="body1">{row.businness}</Typography> : <></>
            }

            {row.i === 4 || row.i === 5 ?
              <CheckIcon sx={{ color: "#1EC99F" }} /> : <></>
            }

            {row.i === 6 && <MoreVert sx={{ color: "#C2C2C2" }} />}
          </TableCell>
        }

        {/* Enterprise */}
        {
          (!isMobile || selectedPage === 3) && <TableCell align="center" sx={{ width: 340 }}>
            {row.i === 1 &&
              <Typography variant="body1">100+</Typography>
            }
            {row.i === 2 || row.i === 3 ?
              <Typography variant="body1">{row.enterprise}</Typography> : <></>
            }
            {row.i === 4 || row.i === 5 ?
              <CheckIcon sx={{ color: "#1EC99F" }} /> : <></>
            }

            {row.i === 6 && <MoreVert sx={{ color: "#C2C2C2" }} />}
          </TableCell>
        }

      </TableRow>

      {
        open && row.details.map((detailRow, i) => {
          return (
            <TableRow key={i} sx={{
              "&:first-of-type": {
                "& td": {
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                },
              },
            }}>
              {/* Name */}
              <TableCell sx={{ width: 200, backgroundColor: "#F9F9F9" }}>
                {detailRow.name}
              </TableCell>

              <TableCell sx={{ backgroundColor: "#F9F9F9", width: 10 }} colSpan={1}>
              </TableCell>

              {/* Standart */}
              {
                (!isMobile || selectedPage === 1) && <TableCell align="center" sx={{ width: 340 }}>
                  {detailRow.name === "Max. Çalışma Odası" ?
                    <Typography variant="body1">{detailRow.standart}</Typography>
                    :
                    <CheckIcon sx={{ color: "#1EC99F" }} />
                  }
                </TableCell>
              }

              {/* Businness */}
              {
                (!isMobile || selectedPage === 2) &&
                <TableCell align="center" sx={{ width: 340, backgroundColor: "#F7F6FF" }}>
                  {detailRow.name === "Max. Çalışma Odası" ?
                    <Typography variant="body1">{detailRow.businness}</Typography>
                    :
                    <CheckIcon sx={{ color: "#1EC99F" }} />
                  }
                </TableCell>
              }

              {/* Enterprise */}
              {
                (!isMobile || selectedPage === 3) && <TableCell align="center" sx={{ width: 340 }}>
                  {detailRow.name === "Max. Çalışma Odası" ?
                    <Typography variant="body1">{detailRow.enterprise}</Typography>
                    :
                    <CheckIcon sx={{ color: "#1EC99F" }} />
                  }
                </TableCell>
              }

            </TableRow>
          )
        })
      }

    </React.Fragment>
  )
}

function CustomPagination({ selectedPage, setSelectedPage }) {
  return (
    <Grid container direction="row" justifyContent="center" style={{ direction: "ltr" }}>
      <Grid item>
        <Button
          onClick={() => setSelectedPage(selectedPage !== 1 ? selectedPage - 1 : selectedPage)}
          sx={{ borderRadius: 3 }}
          disabled={selectedPage === 1}
          variant="contained"
        >
          <KeyboardArrowLeftIcon />
        </Button>
      </Grid>
      <Grid item mt={1.5} mb={4}>
        <Grid container direction="row">
          <Grid
            item
            sx={{
              mr: 0,
              ml: 4,
              height: 13,
              width: 13,
              border: "1px solid gray",
              borderRadius: 7,
              backgroundColor: selectedPage === 1 ? "#331688" : "#fff",
            }} />
          <Grid
            item
            sx={{
              mr: 1,
              ml: 1,
              height: 13,
              width: 13,
              border: "1px solid gray",
              borderRadius: 7,
              backgroundColor: selectedPage === 2 ? "#331688" : "#fff",
            }} />
          <Grid
            item
            sx={{
              ml: 0,
              mr: 4,
              height: 13,
              width: 13,
              border: "1px solid gray",
              borderRadius: 7,
              backgroundColor: selectedPage === 3 ? "#331688" : "#fff",
            }} />
        </Grid>

      </Grid>
      <Grid item>
        <Button
          onClick={() => setSelectedPage(selectedPage !== 3 ? selectedPage + 1 : selectedPage)}
          sx={{ borderRadius: 3 }}
          disabled={selectedPage === 3}
          variant="contained"
        >
          <KeyboardArrowRightIcon />
        </Button>
      </Grid>
    </Grid>
  )
}

CustomPagination.propTypes = {
  selectedPage: PropTypes.number,
  setSelectedPage: PropTypes.func,
}

Row.propTypes = {
  selectedPage: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    i: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    standart: PropTypes.any.isRequired,
    businness: PropTypes.any.isRequired,
    enterprise: PropTypes.any.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        standart: PropTypes.string.isRequired,
        businness: PropTypes.string.isRequired,
        enterprise: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

PricingTable.propTypes = {
  licenseType: PropTypes.string,
  minimal: PropTypes.bool,
}

export default PricingTable
