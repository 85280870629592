import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField, InputAdornment } from "@mui/material";

const Companies = () => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");

  const dummyCompanies = Array.from({ length: 20 }, (_, index) => ({
    name: `Company ${index + 1}`,
    email: `user${index + 1}@company.com`,
    startDate: "2023-01-01",
    endDate: "2024-01-01",
    membershipType: index % 3 === 0 ? "Business" : index % 3 === 1 ? "Standard" : "Enterprise",
    userCount: Math.floor(Math.random() * 16),
  }));

  const getSortedAndFilteredCompanies = () => {
    const filteredCompanies = dummyCompanies.filter((company) =>
      company.name.toLowerCase().includes(filterText.toLowerCase()) ||
      company.email.toLowerCase().includes(filterText.toLowerCase()) ||
      company.membershipType.toLowerCase().includes(filterText.toLowerCase()) ||
      company.userCount.toString().includes(filterText)
    );

    const sortedCompanies = [...filteredCompanies].sort((a, b) => {
      if (sortConfig.key) {
        const directionMultiplier = sortConfig.direction === "asc" ? 1 : -1;
        if (a[sortConfig.key] < b[sortConfig.key]) return -1 * directionMultiplier;
        if (a[sortConfig.key] > b[sortConfig.key]) return 1 * directionMultiplier;
        return 0;
      }
      return 0;
    });

    return sortedCompanies;
  };

  const paginatedCompanies = getSortedAndFilteredCompanies().slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <ArrowDropUpIcon fontSize="small" />
      ) : (
        <ArrowDropDownIcon fontSize="small" />
      );
    }
    return null;
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Firma Listesi
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        margin="normal"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => requestSort("name")} style={{ cursor: "pointer" }}>
                Ad {renderSortIcon("name")}
              </TableCell>
              <TableCell onClick={() => requestSort("email")} style={{ cursor: "pointer" }}>
                Email {renderSortIcon("email")}
              </TableCell>
              <TableCell onClick={() => requestSort("startDate")} style={{ cursor: "pointer" }}>
                Üyelik Başlangıcı {renderSortIcon("startDate")}
              </TableCell>
              <TableCell onClick={() => requestSort("endDate")} style={{ cursor: "pointer" }}>
                Üyelik Bitişi {renderSortIcon("endDate")}
              </TableCell>
              <TableCell onClick={() => requestSort("membershipType")} style={{ cursor: "pointer" }}>
                Üyelik Tipi {renderSortIcon("membershipType")}
              </TableCell>
              <TableCell onClick={() => requestSort("userCount")} style={{ cursor: "pointer" }}>
                Kullanıcı Sayısı {renderSortIcon("userCount")}
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCompanies.map((company, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: "black", fontSize: "x-small" }}>{company.name}</TableCell>
                <TableCell sx={{ color: "black", fontSize: "x-small" }}>{company.email}</TableCell>
                <TableCell sx={{ color: "black", fontSize: "x-small" }}>{company.startDate}</TableCell>
                <TableCell sx={{ color: "black", fontSize: "x-small" }}>{company.endDate}</TableCell>
                <TableCell sx={{ color: "black", fontSize: "x-small" }}>{company.membershipType}</TableCell>
                <TableCell sx={{ color: "black", fontSize: "x-small" }}>{company.userCount}</TableCell>
                <TableCell>
                  <IconButton>
                    <EditIcon color="primary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <TablePagination
          count={getSortedAndFilteredCompanies().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default Companies;
